import { useEffect } from "react";
import axios from 'axios'

export const RedirectIfNecessary = () => {
  useEffect(() => {
    const checkUser = async () => {
      try {
        const response = await axios.get('/api/v1/users/current/?fields=pending_sent_request');
        const data = await response.json();

        if (data.user && data.user.pending_sent_request === null) {
          window.location.href = '/views/home/';
        }
      } catch (e) {
        console.error(e);
      }
    }

    checkUser();
  }, []);

  return null;
};
